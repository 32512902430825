import { signOut } from 'next-auth/react';

const NavbarMainSidebarHelp = () => {
  return (
    <div className="pb-4 border-t border-t-grey-200 pt-4">
      <div className="flex flex-col gap-4 items-start">
        <div className="cursor-pointer block w-full font-semibold text-primary-700" onClick={() => signOut()}>
          Keluar
        </div>
      </div>
    </div>
  );
};

export default NavbarMainSidebarHelp;
