import { Menu } from '@headlessui/react';
import { Avatar, Link } from '@sobat-bangun/ui/atoms';
import { signOut, useSession } from 'next-auth/react';
import { Source_Sans_3 } from 'next/font/google';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { NotificationMenu } from '../../Menu';
import { NavDropdownMenu } from '../../Menu/NavMenu';
import { NavItem } from '../../Nav/NavItem';
import NavbarMainSidebar from './NavbarMainSidebar';
import { TNavbarMainProps } from './types';
import { TTopNavigation } from '../types';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { match } from 'ts-pattern';

const source = Source_Sans_3({
  weight: '400',
  subsets: ['latin'],
});

export const NavbarMain: FC<TNavbarMainProps> = ({ topMenus, notifications, image, name, alwaysActive }): ReactElement => {
  const [scroll, setScroll] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const ref = useDetectClickOutside({
    onTriggered: () => {
      setActiveMenu('');
    },
    allowAnyKey: true,
    disableTouch: true,
  });
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  const isActive = alwaysActive || activeMenu;
  const backgroundClass = isActive ? 'bg-white' : scroll ? 'bg-white' : 'bg-white md:bg-[transparent]';

  const showBlackLogo = isActive ? true : scroll;

  const navTextColor = isActive ? 'text-black' : scroll ? 'md:text-black' : 'text-black md:text-white';

  const hamburgerMenuFill = isActive ? 'fill-black' : scroll ? 'fill-black' : 'fill-black md:fill-white';

  const HamburgerMenu = () => (
    <svg
      className={`block lg:hidden ${hamburgerMenuFill} cursor-pointer hamburger-menu`}
      onClick={() => {
        setShowMobileSidebar(true);
      }}
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 14.5H21.5V12.1667H0.5V14.5ZM0.5 8.66667H21.5V6.33333H0.5V8.66667ZM0.5 0.5V2.83333H21.5V0.5H0.5Z" />
    </svg>
  );
  const router = useRouter();
  const { data } = useSession();
  const _menu_tentang_kami = [
    {
      title: 'Tentang SB',
      items: ['Apa itu SobatBagun?', 'Our Value'],
      link: '/tentang-kami',
    },
    {
      title: 'SBTV',
      items: ['Testimonial', 'Mengenal SobatBangun', 'Sobat Konsul', 'Sobat Arsitek', 'Soba Add-On'],
      link: '/sbtv',
    },
    {
      title: 'Portofolio',
      items: ['Portofolio Inovasi', 'Portofolio Bangun Rumah', 'Portofolio Layanan Desain', 'Portofolio Teknologi Tambahan'],
      link: '/portofolio',
    },
    {
      title: 'Liputan',
      items: ['Liputan Media'],
      link: '/liputan',
    },
  ];

  const _menu_produk_dan_layanan = [
    {
      title: 'Renovasi',
      items: ['Renovasi Perbaikan', 'Renovasi Penambahan Ruang', 'Renovasi Dekoratif', 'Renovasi Lainnya'],
      link: '/layanan',
    },
    {
      title: 'Bangun Rumah',
      items: ['Dapatkan perhitungan estimasi biaya konstruksi, layanan konsultasi dengan Arsitek, dan pembangunan oleh kontraktor profesional dan terpercaya'],
      link: '/layanan/bangun-rumah',
    },
    {
      title: 'Desain Rumah',
      items: ['Temukan desain rumah ramah lingkungan karya Arsitek terbaik sesuai dengan kebutuhan dan gaya hidup'],
      link: '/layanan/desain',
    },
    {
      title: 'Desain Interior',
      items: ['Temukan desain interior template dan custom, dan wujudkan pembangunannya sesuai dengan kebutuhan ruang anda.'],
      link: '/layanan/desain-interior',
    },
    {
      title: 'Teknologi Tambahan',
      items: ['Tambahan sentuhan teknologi untuk wujudkan rumah impian yang diinginkan'],
      link: '/layanan/teknologi-tambahan',
    },
    // TODO Solusi Temporary
    // Solusi Benar nya nanti masih dalam tahap penelitian
    // Perlu menginvestigasi hook useTableFilter
    {
      title: 'Beli Material',
      items: ['Belanja berbagai material yang dibutuhkan untuk kebutuhan proyek Anda'],
      link: '/material?page=1&per_page=12',
    },
  ];

  return (
    <>
      <nav
        ref={ref}
        className={`${backgroundClass} ${source.className} transition-all ease-in-out duration-200 w-full fixed z-50 top-0 border-b border-grey-300 md:border-b-0`}
      >
        <div className="px-6 py-4 lg:p-8 flex justify-between items-center">
          <Link href="/">
            <Image
              src={showBlackLogo ? '/images/logo-black.svg' : '/images/logo.svg'}
              className="hidden md:inline-block"
              quality={50}
              loading={'eager'}
              alt="logo"
              width={125}
              height={36}
              priority
            />
            <Image src="/images/logo-black.svg" className="md:hidden inline-block" quality={50} loading={'eager'} alt="logo" width={125} height={36} />
          </Link>
          <ul className={`hidden lg:flex justify-end items-center gap-6 ${navTextColor}`}>
            {(topMenus as TTopNavigation[])?.map((navMenu, i) => {
              const isActive = router.asPath === navMenu.href;
              return navMenu.children === 'Tentang Kami' ? (
                <NavDropdownMenu
                  key={i}
                  navBg={backgroundClass}
                  title={'Tentang Kami'}
                  items={_menu_tentang_kami}
                  name="about"
                  active={activeMenu === 'about'}
                  onChange={setActiveMenu}
                />
              ) : navMenu.children === 'Produk & Layanan' ? (
                <NavDropdownMenu
                  key={i}
                  navBg={backgroundClass}
                  title={'Produk & Layanan'}
                  items={_menu_produk_dan_layanan}
                  name="products"
                  active={activeMenu === 'products'}
                  onChange={setActiveMenu}
                />
              ) : (
                <NavItem navBg={backgroundClass} key={navMenu.href} href={navMenu.href} isActive={isActive}>
                  {navMenu.children}{' '}
                </NavItem>
              );
            })}
          </ul>
          <div className={`flex items-center space-x-4 ${navTextColor}`}>
            <div className="flex items-center space-x-4">
              {data ? (
                <div className={'flex justify-end items-center gap-6 text-black'}>
                  {notifications && (
                    <NotificationMenu notifications={notifications} href="/notifications">
                      <Image src="/assets/bell.svg" alt="bell" width={18} height={18} className={'text-white'} />
                      {Number(notifications?.meta?.unread) > 0 && (
                        <span className={`text-xs w-fit h-fit py-[2px] px-[6px] rounded-full absolute bg-primary-base bottom-[10px] left-[7px] text-white`}>
                          {Number(notifications?.meta?.unread) > 10 ? '10+' : notifications?.meta?.unread}
                        </span>
                      )}
                    </NotificationMenu>
                  )}

                  <div
                    className="inline-flex relative items-center gap-2 lg:hidden "
                    onClick={() => {
                      setShowMobileSidebar(true);
                    }}
                  >
                    {image || name ? (
                      <Avatar img={image} name={name} />
                    ) : (
                      <Image src="/images/empty-profile.png" width={50} height={50} className="w-[32px] h-[32px] object-cover rounded-full" alt="Empty" />
                    )}
                    <FaChevronDown className={`${navTextColor} text-xs hamburger-menu`} />
                  </div>
                  <div className="hidden lg:inline-flex relative">
                    <Menu>
                      <Menu.Button className="inline-flex items-center gap-2">
                        {image || name ? (
                          <Avatar img={image} name={name} />
                        ) : (
                          <Image src="/images/empty-profile.png" width={50} height={50} className="w-[32px] h-[32px] object-cover rounded-full" alt="Empty" />
                        )}
                        <FaChevronDown className={`${navTextColor} text-xs`} />
                      </Menu.Button>
                      <Menu.Items className="absolute p-2 right-0 top-full mt-2 bg-white rounded-lg inline-flex flex-col gap-2 w-[200px]">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              className={`${active && 'bg-primary-base text-white'} transition-all ease-in-out duration-200 px-3 py-1 rounded-lg text-black`}
                              href="/dashboard"
                            >
                              Dashboard
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={`${
                                active && 'bg-primary-base text-white'
                              } transition-all ease-in-out duration-200 px-3 py-1 rounded-lg text-black cursor-pointer`}
                              onClick={() => signOut()}
                            >
                              Logout
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <section className="lg:flex hidden items-center gap-x-4">
                    {match(process.env.NEXT_PUBLIC_WORKSPACE)
                      .with('customer', () => true)
                      .with('partner', () => true)
                      .otherwise(() => false) && (
                      <Link href="/auth/register" className={`${navTextColor} font-medium`} prefetch={false}>
                        Daftar
                      </Link>
                    )}
                    <Link href="/auth/login" variant="solid" fullWidth={false} prefetch={false}>
                      Masuk
                    </Link>
                  </section>
                  <HamburgerMenu />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </nav>
      <NavbarMainSidebar
        show={showMobileSidebar}
        onClose={() => {
          setShowMobileSidebar(false);
        }}
        menu={{
          topMenus: topMenus as TTopNavigation[],
          subMenus: [
            {
              title: 'Tentang Kami',
              items: _menu_tentang_kami,
            },
            {
              title: 'Produk & Layanan',
              items: _menu_produk_dan_layanan,
            },
          ],
        }}
        name={name || 'Customer'}
        image={image || '/images/empty-profile.png'}
      />
    </>
  );
};
