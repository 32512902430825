import React, { useCallback, useState } from 'react';
import { TNavbarMainSidebarMenu } from '../../types';
import { TbChevronDown } from 'react-icons/tb';
import { motion } from 'framer-motion';
import Link from 'next/link';

const subMenuVariant = {
  active: {
    display: 'block',
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  inactive: {
    display: 'none',
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const NavbarMainSidebarMenu = (props: TNavbarMainSidebarMenu) => {
  const { topMenus, subMenus } = props;
  const [activeSubMenu, setActiveSubMenu] = useState('');
  const getSubItems = useCallback(
    (menuTitle: string) => {
      if (!subMenus?.length) return;
      return subMenus.find((item) => item.title === menuTitle)?.items;
    },
    [subMenus]
  );

  return (
    <div className="py-4 flex flex-col gap-4">
      {!!topMenus &&
        topMenus?.map((menu, idx) => {
          const getMenuSubItems = getSubItems(menu.children as string);
          if (getMenuSubItems) {
            return (
              <div key={idx}>
                <div
                  className="py-1 flex justify-between items-center text-grey-1000 hover:text-primary-700 transition-all ease-in-out duration-200 cursor-pointer"
                  onClick={() => {
                    if (activeSubMenu === menu.children) {
                      setActiveSubMenu('');
                    } else {
                      setActiveSubMenu(menu.children as string);
                    }
                  }}
                >
                  <span className="font-semibold">{menu.children}</span>
                  <TbChevronDown className={`transform transition-all ease-in-out duration-200 ${activeSubMenu === menu.children ? 'rotate-180' : 'rotate-0'} `} />
                </div>
                {!!getMenuSubItems && (
                  <motion.div variants={subMenuVariant} animate={activeSubMenu === menu.children ? 'active' : 'inactive'}>
                    <div className="flex flex-col gap-4 mt-3">
                      {getMenuSubItems.map((subItem, i) => (
                        <Link
                          key={i}
                          href={subItem.link || '#'}
                          className="text-grey-1000 hover:text-primary-700 transition-all ease-in-out duration-200 cursor-pointer"
                        >
                          {subItem.title}
                        </Link>
                      ))}
                    </div>
                  </motion.div>
                )}
              </div>
            );
          } else {
            return (
              <Link
                key={idx}
                href={menu.href || '#'}
                className="py-1 text-grey-1000 block font-semibold w-full hover:text-primary-700 transition-all ease-in-out duration-200 cursor-pointer"
              >
                {menu.children}
              </Link>
            );
          }
        })}
    </div>
  );
};

export default NavbarMainSidebarMenu;
