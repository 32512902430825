import Link from 'next/link';
import { TNavbarDropdownMenuProps } from './types';
import { FC } from 'react';
import { TbChevronDown } from 'react-icons/tb';

export const NavDropdownMenu: FC<TNavbarDropdownMenuProps> = (props) => {
  return (
    <section className="inline-flex">
      <button
        className={`text-sm ${
          props.navBg === 'bg-white' ? 'text-black' : 'text-white'
        } hover:text-primary-base font-semibold cursor-pointer menu-item inline-flex items-center gap-3`}
        onClick={() => {
          props.onChange(props.active ? '' : props.name);
        }}
      >
        {props.title} <TbChevronDown />
      </button>
      {props.active && (
        <div className="bg-white top-[100px] left-0 px-[32px] py-4 justify-start w-full h-auto min-h-[272px] z-40 absolute flex items-start">
          <h1 className="text-black w-[20%] text-[24px] font-[600]">{props.title}</h1>
          <div className="h-full border-r-2 text-grey-100"></div>
          <div
            className={`flex w-full justify-center ${props.title === 'Tentang Kami' && 'xl:mr-[160px]'} ${
              props.title === 'Tentang Kami' ? 'gap-x-[62px]' : 'gap-x-[32px]'
            }`}
          >
            {props.items.map((menu, key) => (
              <nav key={key} className="flex flex-col gap-y-4 justify-between">
                <div className="flex flex-col gap-y-4 justify-start">
                  <h1 className="text-[16px] text-black font-[600]">{menu.title}</h1>
                  <ul className="flex flex-col max-w-[187px] text-[14px] text-grey-500 gap-y-2 font-normal">
                    {menu.items.map((item, key) => (
                      <li key={key}>{item}</li>
                    ))}
                  </ul>
                </div>
                {menu.link && (
                  <Link href={menu.link as string} className="text-primary-base font-[600] text-[16px]">
                    Lihat Lainnya
                  </Link>
                )}
              </nav>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};
