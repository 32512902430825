import { Menu } from '@headlessui/react';
import { INotificationProps, TNotificationItem } from './types';
import { Fragment, Ref, forwardRef } from 'react';
import Image from 'next/image';

import NextLink from 'next/link';
import { NotificationTypeMapper } from '@sobat-bangun/utils';

export const NotificationItem = forwardRef(({ actionType = 'link', href, ...props }: TNotificationItem, ref: Ref<HTMLButtonElement>) => {
  if (actionType === 'button') {
    return (
      <button
        ref={ref}
        onClick={props.action as () => void}
        className={`${props.read_at ? 'bg-white' : 'bg-secondary-100 bg-opacity-10'} hover:bg-grey-100 p-2 w-full`}
      >
        <div className="text-xs text-secondary-400 my-1 flex items-center justify-start">
          <Image
            src={isNotificationOrderType(props.type) ? '/assets/notif-order.svg' : '/assets/notif-information.svg'}
            alt="bell"
            width={18}
            height={18}
            className="mr-2"
          />{' '}
          {getClassifiactionOrderType(props.type)} - {props.created_at.toString()}
        </div>
        <div className="flex text-sm font-semibold truncate">{NotificationTypeMapper(props.type)}</div>
        <div className="flex text-xs font-light truncate">{props.message}</div>
      </button>
    );
  }
  return (
    <NextLink
      href={href ? href : `/notifications/${props.id}`}
      className={`${props.read_at ? 'bg-grey-100 bg-opacity-30' : 'bg-secondary-100 bg-opacity-10'} hover:bg-grey-100 p-2 w-full`}
    >
      <div className="text-xs text-secondary-400 my-1 flex items-center justify-start">
        <Image
          src={isNotificationOrderType(props.type) ? '/assets/notif-order.svg' : '/assets/notif-information.svg'}
          alt="bell"
          width={18}
          height={18}
          className="mr-2"
        />
        {getClassifiactionOrderType(props.type)} - {props.created_at.toString()}
      </div>
      <div className="text-sm font-semibold truncate">{NotificationTypeMapper(props.type)}</div>
      <div className="text-xs font-light truncate">{props.message}</div>
    </NextLink>
  );
});

export const NotificationMenu = (props: INotificationProps) => {
  return (
    <div className="sm:relative z-10 flex">
      <Menu>
        <Menu.Button className="relative">{props.children}</Menu.Button>
        <Menu.Items className="absolute flex flex-col origin-top-right right-0 w-full sm:w-[320px] z-20 lg:w-96 mt-2 shadow bg-white ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md">
          <div className="p-1 flex flex-col">
            <div className="flex justify-start items-center border-b-2 border-primary-600 border-opacity-75 p-2">Notifikasi Baru Diterima</div>
            {props?.notifications?.data?.slice?.(0, 5)?.map((item, index) => {
              return (
                <Menu.Item key={index} as={Fragment}>
                  {({ active }) => <NotificationItem {...item} href={props.href} />}
                </Menu.Item>
              );
            })}
            <Menu.Item>
              <div className="flex justify-center items-center p-2 ">
                <NextLink href="/notifications" className="w-full text-center text-sm">
                  Lihat Semua ({props.notifications?.meta?.unread} belum dibaca)
                </NextLink>
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
};

const isNotificationOrderType = (type: string) => {
  const orderType = [
    'ASSIGNED NEW ORDER ADMIN TO PARTNER',
    'PARTNER REJECT ASSIGNED ORDER FROM ADMIN',
    'PARTNER APPROVE ASSIGNED ORDER FROM ADMIN',
    'BIDDING CLOSED',
    'OFFERING MATERIAL PRICE FROM CUSTOMER',
    'OFFERING MATERIAL PRICE FROM PARTNER',
    'VENDOR RECEIVE OFFERED MATERIAL PRICE FROM ADMIN',
    'CUSTOMER RECEIVE MATERIAL PRICE FROM ADMIN',
    'ADMIN RECEIVE MATERIAL ORDER REQUEST FROM CUSTOMER',
    'VENDOR RECEIVE MATERIAL ORDER REQUEST FROM ADMIN',
    'SHIPPING MATERIAL STATUS',
    'ADMIN GENERATE INVOICE FOR PAYING DP TO CUSTOMER',
    'ADMIN GENERATE INVOICE FOR PAYING PROJECT BUDGET',
    'PAYING PROJECT BUDGET FROM CUSTOMER',
  ];

  return orderType.includes(type);
};

const getClassifiactionOrderType = (type: string) => {
  const orderType = [
    'ASSIGNED NEW ORDER ADMIN TO PARTNER',
    'PARTNER REJECT ASSIGNED ORDER FROM ADMIN',
    'PARTNER APPROVE ASSIGNED ORDER FROM ADMIN',
    'BIDDING CLOSED',
    'OFFERING MATERIAL PRICE FROM CUSTOMER',
    'OFFERING MATERIAL PRICE FROM PARTNER',
    'VENDOR RECEIVE OFFERED MATERIAL PRICE FROM ADMIN',
    'CUSTOMER RECEIVE MATERIAL PRICE FROM ADMIN',
    'ADMIN RECEIVE MATERIAL ORDER REQUEST FROM CUSTOMER',
    'VENDOR RECEIVE MATERIAL ORDER REQUEST FROM ADMIN',
    'SHIPPING MATERIAL STATUS',
    'ADMIN GENERATE INVOICE FOR PAYING DP TO CUSTOMER',
    'ADMIN GENERATE INVOICE FOR PAYING PROJECT BUDGET',
    'PAYING PROJECT BUDGET FROM CUSTOMER',
  ];

  const referralType = ['REFERRAL USED', 'REFERRAL MAXIMUM USED'];

  const documentType = [
    'APPROVED DOCUMENT FOR ACCOUNT PARTNER',
    'REJECTED DOCUMENT FOR ACCOUNT PARTNER',
    'SIGNING PKS ADMIN TO PARTNER',
    'SIGNING PKS PARTNER TO CUSTOMER',
    'PARTNER SUBMITTING BAST TO CUSTOMER',
    'PARTNER SUBMITTING BAPP TO CUSTOMER',
    'CUSTOMER APPROVE BAST',
    'CUSTOMER APPROVE BAPP',
    'PARTNER GENERATE LOP',
  ];

  return orderType.includes(type) ? 'Pesanan' : referralType.includes(type) ? 'Referral' : documentType.includes(type) ? 'Dokumen' : 'Pemberitahuan';
};
