import { motion } from 'framer-motion';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { RiCloseFill } from 'react-icons/ri';
import { TNavbarMainSidebar } from '../../types';
import NavbarMainSidebarHelp from './NavbarMainSidebarHelp';
import NavbarMainSidebarMenu from './NavbarMainSidebarMenu';
import NavbarMainSidebarProfile from './NavbarMainSidebarProfile';

const sidebarVariants = {
  active: {
    display: 'block',
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  inactive: {
    display: 'none',
    opacity: 0,
    transition: {
      y: { stiffness: 1000, velocity: 100 },
    },
  },
};

const NavbarMainSidebar = (props: TNavbarMainSidebar) => {
  const { menu, show, onClose } = props;
  const { data } = useSession();
  const ref = useDetectClickOutside({
    onTriggered: (e) => {
      if (show === true) {
        // @ts-ignore
        const targetClass = e.target?.className;
        if (typeof targetClass === 'string' && !targetClass?.includes('hamburger-menu')) {
          onClose();
        }
      }
    },
  });

  return (
    show && (
      <div className="fixed w-full overflow-y-scroll h-full top-0 z-50 bg-grey-1000/50">
        <div
          ref={ref}
          className={`lg:hidden w-[254px] max-w-[80%] h-full bg-white pt-[26px] pb-4 px-4 fixed right-0 top-0 transform transition-all ease-in-out duration-300 overflow-y-auto ${
            show ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="flex justify-between mb-[26px]">
            <Image src={'/images/logo-black.svg'} quality={100} loading={'eager'} alt="logo" width={107} height={31} />
            <button onClick={() => onClose()}>
              <RiCloseFill color="#000000" fontSize={24} />
            </button>
          </div>
          <div>
            <NavbarMainSidebarProfile name={props.name} image={props.image} />
            <NavbarMainSidebarMenu {...menu} />
            {!!data && <NavbarMainSidebarHelp />}
          </div>
        </div>
      </div>
    )
  );
};

export default NavbarMainSidebar;
