import clsx from 'clsx';
import Image from 'next/image';
import ReactAvatar from 'react-avatar';

type AvatarProps = {
  img?: string;
  size?: 'sm' | 'md';
  name?: string;
};

export const Avatar = ({ img, size = 'sm', name }: AvatarProps) => {
  const style = clsx(`relative rounded-full overflow-hidden`, {
    'w-8 h-8': size === 'sm',
    'w-[80px] h-[80px]': size === 'md',
  });

  const sizes = {
    sm: 32,
    md: 80,
  };

  return (
    <div className={style}>
      {img ? (
        <Image src={img || ''} alt={img} fill sizes={String(sizes[size] * 2) + 'px'} className="rounded-full h-full w-full" />
      ) : (
        <ReactAvatar name={name || ''} size={String(sizes[size])} maxInitials={2} />
      )}
    </div>
  );
};

export default Avatar;
