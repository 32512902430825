import Link from 'next/link';
import { TNavItemProps } from '../types';
import clsx from 'clsx';

export const NavItem = ({ children, href, isActive, navBg = 'bg-white', textSize = 'sm', isSecondary, bold = true }: TNavItemProps) => {
  const contentStyle = clsx('lg:flex hidden cursor-pointer hover:text-primary-600', {
    'text-primary-600 hover:text-primary-900 ': isActive,
    'text-base': textSize === 'md',
    'text-sm': textSize === 'sm',
    'text-black': navBg === 'bg-white',
    'text-grey-600': isSecondary,
    'font-semibold': bold,
  });

  return (
    <li>
      {href === '#' ? (
        <span className={contentStyle}>{children}</span>
      ) : (
        <Link href={href as string} className={contentStyle}>
          {children}
        </Link>
      )}
    </li>
  );
};
