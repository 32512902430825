import { Avatar } from '@sobat-bangun/ui/atoms';
import { useSession } from 'next-auth/react';
import Link from 'next/link';

type TNavbarMainSidebarProfileProps = {
  name: string;
  image: string;
};

const NavbarMainSidebarProfile = ({ name, image }: TNavbarMainSidebarProfileProps) => {
  const { data } = useSession();

  return (
    <div className="pb-4 border-b border-b-grey-200">
      {!data && (
        <div className="flex gap-4 items-center">
          <Link href="/auth/register" className="font-semibold transition-all ease-in-out duration-200 text-grey-1000 hover:text-primary-700" prefetch={false}>
            Daftar
          </Link>
          <span className="h-[14px] w-[1px] inline-block bg-grey-400" />
          <Link href="/auth/login" className="font-semibold transition-all ease-in-out duration-200 text-grey-1000 hover:text-primary-700" prefetch={false}>
            Masuk
          </Link>
        </div>
      )}
      {!!data && (
        <div>
          <div className="flex items-center gap-4 mb-4">
            <div className="w-8 h-8">
              <Avatar img={image} name={name} />
            </div>
            <span className="line-clamp-2 break-words">{name}</span>
          </div>
          <div>
            <Link className="font-semibold text-grey-1000 hover:text-primary-700 transition-all ease-in-out duration-200" href="/dashboard">
              Dashboard
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarMainSidebarProfile;
